// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Dropzone Uploader
@import "dropzone-uploader";

body {
    /* Location of the image */
    background-image: url('/images/backgrounds/cinema-1269996.jpg');

    /* Background image is centered vertically and horizontally at all times */
    background-position: center center;

    /* Background image doesn't tile */
    background-repeat: no-repeat;

    /* Background image is fixed in the viewport so that it doesn't move when
        the content's height is greater than the image's height */
    background-attachment: fixed;

    /* This is what makes the background image rescale based
        on the container's size */
    background-size: cover;

    /* Set a background color that will be displayed
        while the background image is loading */
    background-color: #000;
}

#IEwarning{
    display: none;
}

.toplogo {
    position: relative;
    top:-13px;
    margin-bottom:-26px;
    height:36px;
}
.imdbtmblogo {
    height:24px;
    margin-right:5px;
}
.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
.user-image {
    margin: -10px 10px;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
* Star Rating
*/
.rating {
    display: inline-block;
    position: relative;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
}

.rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
}

.rating label:last-child {
    position: static;
}

.rating label:nth-child(1) {
    z-index: 5;
}

.rating label:nth-child(2) {
    z-index: 4;
}

.rating label:nth-child(3) {
    z-index: 3;
}

.rating label:nth-child(4) {
    z-index: 2;
}

.rating label:nth-child(5) {
    z-index: 1;
}

.rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.rating label .icon {
    float: left;
    color: transparent;
}

.rating label:last-child .icon {
    color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
    color: #d4af37;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
    color: #000;
    text-shadow: 0 0 5px #09f;
}

.search {
    position: relative;
}

.search-results {
    position: absolute;
    top: 36px;
    left: 56px;
    right: 13px;
    z-index: 100;

    .list-group-item {
        padding: 0.45rem 1.25rem;
    }
}

.loading {
    position: absolute;
    right: 25px;
    top: 6px;
    height: 1.5rem;
    width: 1.5rem;
}

.cursor-none {
    cursor: none;
}

.btn.btn-primary.disabled-full,
.btn.btn-primary.disabled-full:hover {
    background-color: #227dc7;
    cursor: default;
}

.position-unset {
    position: unset;
}

.video-modal-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
}

.video-modal {
    position: relative;
    width: 70%;
    z-index: 101;
    display: flex;
    flex-direction: column;
}

.video-modal-close {
    position: absolute;
    right: 50px;
    top: 25px;
    font-size: x-large;
    height: 30px;
    width: 165px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.footer {
    width: 100%;
    height: 50px;
    font-size:0.8em;
    background-color: rgba(0, 0, 0, 0.90);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.country-flag-name {
    font-size: 1.2rem;
    margin-top: .5rem;
}

.country-flag-voted {
    background-image: url('/images/voted-stamp.png');
    background-size: 120px 120px;
    position: relative;
    top: -110px;
    left: 0;
    z-index: 40;
    width: 120px;
    height: 120px;
    margin-bottom: -120px;
}

@import "multistep-indicator";