/**
 * Styling of the uploder
 */

.anillusion-upload-message {
    color:#777;
    font-size:1.2rem
}
.filesize-progress {
    color:#777;
    font-size:0.7rem
}
.hidden {
    display: none;
    opacity: 0;
}

/**
 * Initial hide/show
 */
.progress {
    height: 8px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#drop-area, .drop-area {
    border: 3px dashed #bbb;
    border-radius: 20px;
    width: 90%;
    font-family: sans-serif;
    margin: 20px auto 10px auto;
    padding: 20px;
}
#upload-stayonpage {
    color:#ddd;
    font-size:1.7rem;
    text-align: center;
    width:100%;
    margin: 35px 0px 20px 0px;
    opacity: 1;
    transition: opacity 1s;
}

#uploads {
    width:85%;
    margin: 0px auto;
    padding:10px;
    opacity: 1;
    transition: opacity 2s;
}
#uploads h3 {
    color:#555;
    font-size: 1.1rem;
}
#uploads .upload-item {
    border: 2px solid #eee;
    border-radius: 10px;
    padding:10px;
    margin-top: 10px;
}
#uploads .upload-item .upload-status {
    color:#777;
    font-size:0.9rem
}
#uploads .upload-item .upload-status .fa-fingerprint {
    font-size: 20px;
    margin-top: 5px;
    color: #bbb;
}
.show-checksum {
    color: #999;
}
#drop-area.highlight, .drop-area.highlight {
    border: 5px dashed #777;
    margin: 18px auto 8px auto;
}
.my-form {
    color: #bbb;
    margin: 0;
    padding: 40px 0px;
    width:100%;
    text-align: center;
}
.my-form .fas {
    font-size: 5em;
    margin-bottom: 15px;
}
#gallery {
    margin-top: 10px;
}
#gallery img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
}
.button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.button:hover {
    background: #ddd;
}
#fileElem, .fileElem {
    display: none;
}
