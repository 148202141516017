#multi-step-nav {
    overflow: hidden;
}
#multi-step-nav ol {
    display: flex;
    padding-right:40px;
    flex-direction: row;
    justify-content: space-between;
}
#multi-step-nav ol li {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: center;
    user-select: none;
    top:15px;
}
#multi-step-nav ol li .count {
    background-color: grey;
    color: #fff;
    width: 30px;
    height:30px;
    border-radius: 50%;
    padding: 5px 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    z-index: 10;
}

#multi-step-nav ol li.visited .count, #multi-step-nav ol li.current .count {
    background-color:#96c03d;
}

#multi-step-nav ol li .count::after {
    z-index: 1;
    display: block;
    position: relative;
    top:-15px;
    left:20px;
    right:auto;
    width:230px;
    background-color: #bbb;
    content: '';
    height: 4px;
}

#multi-step-nav ol li.visited > .count::after {
    background-color:#96c03d;
}

#multi-step-nav ol li:last-child .count::after {
    z-index: 2;
    background-color:#fff;
}